import React from "react";
import { GeoAltFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { Avatar } from "@nextui-org/react";
import { imgServer } from "../services";

export default function UserStrip(props) {
  const navigate = useNavigate();
  const { user } = props;

  return (
    <div
      className="w-full bg-gray-800 p-2 mx-auto rounded-md text-white flex items-center gap-2 cursor-pointer hover:bg-gray-700"
      onClick={() => navigate(`/${user.id}`)}
    >
      <Avatar className="mx-1" src={`${imgServer}${user?.pic}`} size="md" />
      <div className="mr-2 grow">
        <div className="flex items-baseline">
          <p>{user.name}</p>
          <p className="text-gray-400 text-xs ml-2">{user.position}</p>
        </div>
        <div className="mt-1 flex items-center">
          <GeoAltFill className="text-green-500 text-xs mr-1" />
          <p className="text-gray-400 text-xs">{`${user.location} (${user.country})`}</p>
        </div>
      </div>
      <div className="mx-4 ml-auto flex flex-col items-end">
        <p className="text-sm text-green-500 font-semibold">{`Exp: ${user.experience} years`}</p>
        <p className="text-xs text-gray-400 text-right">{`${user.projects} finished projects`}</p>
      </div>
    </div>
  );
}
