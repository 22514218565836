import {
  Avatar,
  Button,
  Divider,
  Input,
  ScrollShadow,
} from "@nextui-org/react";
import React, { useEffect, useRef, useState } from "react";
import MessageBubble from "./MessageBubble";
import { ArrowReturnLeft } from "react-bootstrap-icons";
import { useParams } from "react-router-dom";
import {
  fetchConversation,
  fetchConversations,
  getMyId,
  sendMessage,
} from "../services";
import { auth } from "../firebase";
import moment from "moment";

export default function Messages(props) {
  const { id } = useParams();
  const lastBubble = useRef(null);
  const myId = getMyId();
  const isLoggedIn = auth.currentUser !== null;
  const [messagesState, setMessagesState] = useState({
    selected: -1,
    input: "",
  });
  const [conversations, setConversations] = useState([]);
  const [loadedConversation, setLoadedConversation] = useState(null);

  const getConversations = async () => {
    const res = await fetchConversations();
    if (Array.isArray(res)) {
      setConversations(res);
      setMessagesState({ ...messagesState, selected: 0 });
      getConversation(res[0].id);
    }
  };

  const getConversation = async (id) => {
    const res = await fetchConversation(id);
    if (res) {
      setLoadedConversation(res);
      setTimeout(() => scrollToBottom(), 100);
    }
  };

  useEffect(() => {
    getConversations();
  }, [isLoggedIn]);

  if (id) {
    if (conversations.some((element) => element?.other === id)) {
    } else {
      const conv = {
        id: null,
        participants: [id.toString(), myId],
        messages: [],
        lastMessage: "No messages yet",
        updated: "just now",
        isRead: true,
      };
      setConversations([...conversations, conv]);
      setMessagesState({
        ...messagesState,
        selected: conversations.length,
      });
    }
  }

  const conversationList = conversations.map((element, index) => {
    const unread = element.unread;
    const updatedTime = moment(element.updated).fromNow();
    return (
      <div
        key={index}
        className={`hover:bg-slate-800 rounded-2xl cursor-pointer p-3 flex gap-2 items-center mb-2 ${
          unread
            ? "bg-blue-950 bg-opacity-50 text-white"
            : "bg-slate-900 text-gray-400"
        } ${messagesState.selected === index ? "border border-slate-500" : ""}`}
        onClick={() => {
          setMessagesState({ ...messagesState, selected: index });
          getConversation(element.id);
        }}
      >
        <Avatar showFallback src={element.otherImg} />
        <div className="max-w-64">
          <p className="font-semibold">{element.otherName}</p>
          <p
            className={`text-gray-500 truncate text-sm ${
              unread ? "font-semibold" : ""
            }`}
          >
            {element.lastMessage}
          </p>
        </div>
        <p className="ml-auto text-xs text-gray-600">{updatedTime}</p>
      </div>
    );
  });

  const messageBubbles =
    loadedConversation?.messages?.map((element, index) => {
      return (
        <MessageBubble
          key={index}
          content={element.message}
          time={moment(element.timestamp).format("L LT")}
          isOwner={element.sender === myId}
        />
      );
    }) || null;

  const scrollToBottom = () => {
    lastBubble.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  const submitMessage = async () => {
    if (messagesState.selected !== -1) {
      await sendMessage(
        messagesState.input,
        conversations[messagesState.selected].other
      );
      setMessagesState({ ...messagesState, input: "" }); // Input is not available after this
      getConversation(conversations[messagesState.selected].id);
    }
  };

  return (
    <div className="mt-24 grid grid-cols-3 p-8 gap-5 w-full grow max-w-screen-xl mx-auto">
      <div className="h-full">{conversationList}</div>
      {messagesState.selected !== -1 ? (
        <div
          className="col-span-2 flex flex-col bg-gray-900 bg-opacity-40 p-6 rounded-xl"
          style={{ height: "70vh" }}
        >
          <p className="text-white font-semibold text-xl pb-3">
            {conversations[messagesState.selected]?.otherName}
          </p>
          <Divider className="mb-4" />
          <ScrollShadow className="flex flex-col">
            {messageBubbles}
            <div ref={lastBubble} />
          </ScrollShadow>
          <div className="flex gap-3 mt-4">
            <Input
              value={messagesState.input}
              onValueChange={(e) =>
                setMessagesState({ ...messagesState, input: e })
              }
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  submitMessage();
                }
              }}
            />
            <Button
              startContent={<ArrowReturnLeft />}
              onClick={() => submitMessage()}
            >
              Send
            </Button>
          </div>
        </div>
      ) : (
        <div className="col-span-2 text-lg flex items-center bg-gray-900 rounded-xl">
          <p className="text-gray-400 mx-auto text-center">
            No conversation selected
          </p>
        </div>
      )}
    </div>
  );
}
