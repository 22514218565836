import { Tooltip } from "@nextui-org/react";
import React from "react";

export default function MessageBubble(props) {
  return (
    <Tooltip content={props.time}>
      <div
        className={`${
          props.isOwner ? "bg-blue-900" : "bg-slate-700"
        } rounded-xl p-2 my-1 text-sm ${props.isOwner ? "ml-auto" : "mr-auto"}`}
      >
        <p className="text-gray-200">{props.content}</p>
      </div>
    </Tooltip>
  );
}
