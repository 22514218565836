import React, { useEffect, useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Avatar,
  Badge,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  DropdownSection,
} from "@nextui-org/react";
import {
  BellFill,
  BoxArrowLeft,
  BriefcaseFill,
  Bullseye,
  ChatFill,
  Discord,
  PencilFill,
  PersonFill,
  PlusLg,
} from "react-bootstrap-icons";
import { auth } from "./../firebase";
import { useNavigate } from "react-router-dom";
import Logo from "../Logo.png";
import { fetchUnread, imgServer } from "../services";

export default function Navigation(props) {
  const [unread, setUnread] = useState(0);
  const navigate = useNavigate();
  const isLoggedIn = auth.currentUser !== null;

  useEffect(() => {
    getUnread();
  }, [isLoggedIn]);

  const getUnread = async () => {
    if (isLoggedIn) {
      const res = await fetchUnread();
      if (res) {
        setUnread(res);
      }
    }
  };

  return (
    <Navbar className="flex w-full fixed bg-transparent">
      <NavbarBrand onClick={() => navigate("/")} className="cursor-pointer">
        <div
          className="w-6 h-6 sm:w-10 sm:h-10 sm:mr-2"
          style={{
            backgroundImage: `url(${Logo})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
          }}
        ></div>
        <p className="hidden sm:block font-semibold text-slate-200">
          HackerHive
        </p>
      </NavbarBrand>
      <NavbarContent justify="center">
        <Button
          variant="flat"
          startContent={<BriefcaseFill />}
          className="hidden sm:flex"
          onClick={() => navigate("/offers")}
        >
          Jobs
        </Button>
        <Button
          variant="flat"
          startContent={<Bullseye />}
          className="hidden sm:flex"
          onClick={() => navigate("/radar")}
        >
          Radar
        </Button>
        <Button
          variant="flat"
          isIconOnly
          className="hidden sm:flex"
          startContent={<Discord />}
          onClick={() => window.open("https://discord.gg/fnq5TprnGN", "_blank")}
        />
      </NavbarContent>
      {isLoggedIn ? (
        <NavbarContent
          className="ml-auto text-green-400 flex gap-2 text-lg"
          justify="end"
        >
          <NavbarItem className="cursor-pointer">
            <Dropdown
              classNames={{
                base: "mt-8",
                content: "bg-gray-900 text-slate-200",
              }}
            >
              <DropdownTrigger>
                <Badge
                  isInvisible={
                    props.profile && props.profile.notifications?.length === 0
                  }
                  size="sm"
                  content="3"
                  color="danger"
                  showOutline={false}
                >
                  <DropdownTrigger>
                    <Button variant="light" isIconOnly>
                      <BellFill />
                    </Button>
                  </DropdownTrigger>
                </Badge>
              </DropdownTrigger>
              <DropdownMenu
                aria-label="Static Actions"
                emptyContent={
                  <p className="text-center">You have no notifications</p>
                }
              ></DropdownMenu>
            </Dropdown>
          </NavbarItem>
          <NavbarItem className="cursor-pointer">
            <Badge
              isInvisible={unread === 0}
              size="sm"
              content={unread}
              color="danger"
              showOutline={false}
            >
              <Button
                variant="light"
                isIconOnly
                onClick={() => navigate(`messages`)}
              >
                <ChatFill />
              </Button>
            </Badge>
          </NavbarItem>
          <NavbarItem className="cursor-pointer">
            <Dropdown classNames={{ content: "bg-gray-900 text-slate-200" }}>
              <DropdownTrigger>
                <Button variant="light" isIconOnly radius="full">
                  <Avatar
                    isBordered
                    src={`${imgServer}${props.profile?.pic}`}
                    size="sm"
                  />
                </Button>
              </DropdownTrigger>
              <DropdownMenu aria-label="Static Actions">
                <DropdownSection
                  showDivider
                  classNames={{ divider: "bg-slate-500" }}
                >
                  <DropdownItem
                    startContent={<PlusLg />}
                    key="newoffer"
                    onClick={() => navigate(`/offers/create`)}
                  >
                    Create offer
                  </DropdownItem>
                </DropdownSection>
                <DropdownSection>
                  <DropdownItem
                    startContent={<PersonFill />}
                    key="myprofile"
                    onClick={() => navigate(`/${auth.currentUser.uid}`)}
                  >
                    My profile
                  </DropdownItem>
                  <DropdownItem
                    startContent={<PencilFill />}
                    key="editprofile"
                    onClick={() => navigate(`/profile/edit`)}
                  >
                    Edit profile
                  </DropdownItem>
                  <DropdownItem
                    startContent={<BriefcaseFill />}
                    key="jobs"
                    onClick={() => navigate(`/offers/manage`)}
                  >
                    Manage offers
                  </DropdownItem>
                  <DropdownItem
                    startContent={<BoxArrowLeft />}
                    key="logout"
                    className="text-danger"
                    color="danger"
                    onClick={async () => {
                      await auth.signOut();
                      navigate("/");
                    }}
                  >
                    Log out
                  </DropdownItem>
                </DropdownSection>
              </DropdownMenu>
            </Dropdown>
          </NavbarItem>
        </NavbarContent>
      ) : (
        <NavbarContent
          className="ml-auto text-green-400 flex gap-5 text-lg"
          justify="end"
        >
          <NavbarItem>
            <Button
              className="font-semibold"
              size="sm"
              color="default"
              onClick={() => navigate("/register")}
            >
              Sign Up
            </Button>
          </NavbarItem>
          <NavbarItem>
            <Button
              className="font-semibold"
              size="sm"
              color="primary"
              onClick={() => navigate("/login")}
            >
              Log In
            </Button>
          </NavbarItem>
        </NavbarContent>
      )}
    </Navbar>
  );
}
