import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Pagination,
  Select,
  SelectItem,
  Spinner,
  Switch,
} from "@nextui-org/react";
import OfferStrip from "./OfferStrip";
import { getOffers } from "../services";

export default function OfferExplorer(props) {
  const [filters, setFilters] = useState({
    location: "all",
    remote: true,
    type: "commercial",
  });
  const [offers, setOffers] = useState(null);

  useEffect(() => {
    fetchOffers();
  }, [filters]);

  const fetchOffers = async () => {
    setOffers(null);
    const results = await getOffers(
      filters.location,
      filters.type,
      filters.remote,
      false,
      20,
      true
    );
    if (results) {
      setOffers(results);
    }
  };

  const offerList = offers?.map((offer) => {
    return <OfferStrip offer={offer} />;
  });

  return (
    <div className="flex flex-col w-full xl:w-3/5 mt-24 h-full grow p-4 sm:p-10 mx-auto">
      <div className="grid grid-cols-2 sm:flex gap-4">
        <ButtonGroup className="col-span-2">
          <Button
            color={filters.type === "commercial" ? "warning" : "default"}
            onClick={() => setFilters({ ...filters, type: "commercial" })}
          >
            Commercial
          </Button>
          <Button
            color={filters.type === "non-commercial" ? "warning" : "default"}
            onClick={() => setFilters({ ...filters, type: "non-commercial" })}
          >
            Non-commercial
          </Button>
        </ButtonGroup>
        <Select
          label="Location"
          size="sm"
          selectedKeys={[filters.location]}
          onSelectionChange={(selection) => {
            setFilters({ ...filters, location: Array.from(selection)[0] });
          }}
        >
          <SelectItem key="all">Any</SelectItem>
          <SelectItem key="Remote">Remote</SelectItem>
          <SelectItem key="Argentina">Argentina</SelectItem>
          <SelectItem key="Australia">Australia</SelectItem>
          <SelectItem key="Brazil">Brazil</SelectItem>
          <SelectItem key="Canada">Canada</SelectItem>
          <SelectItem key="Chile">Chile</SelectItem>
          <SelectItem key="China">China</SelectItem>
          <SelectItem key="Egypt">Egypt</SelectItem>
          <SelectItem key="Finland">Finland</SelectItem>
          <SelectItem key="France">France</SelectItem>
          <SelectItem key="Germany">Germany</SelectItem>
          <SelectItem key="India">India</SelectItem>
          <SelectItem key="Italy">Italy</SelectItem>
          <SelectItem key="Japan">Japan</SelectItem>
          <SelectItem key="Malaysia">Malaysia</SelectItem>
          <SelectItem key="Mexico">Mexico</SelectItem>
          <SelectItem key="Netherlands">Netherlands</SelectItem>
          <SelectItem key="NewZealand">New Zealand</SelectItem>
          <SelectItem key="Poland">Poland</SelectItem>
          <SelectItem key="Russia">Russia</SelectItem>
          <SelectItem key="Singapore">Singapore</SelectItem>
          <SelectItem key="SouthAfrica">South Africa</SelectItem>
          <SelectItem key="SouthKorea">South Korea</SelectItem>
          <SelectItem key="Spain">Spain</SelectItem>
          <SelectItem key="Sweden">Sweden</SelectItem>
          <SelectItem key="Ukraine">Ukraine</SelectItem>
          <SelectItem key="UnitedArabEmirates">United Arab Emirates</SelectItem>
          <SelectItem key="UnitedKingdom">United Kingdom</SelectItem>
          <SelectItem key="UnitedStates">United States</SelectItem>
          <SelectItem key="Vietnam">Vietnam</SelectItem>
        </Select>
        <Switch
          value={filters.remote}
          onValueChange={(e) => setFilters({ ...filters, remote: e })}
        >
          Remote
        </Switch>
      </div>
      {offers ? (
        <div className="mt-6 flex flex-col gap-2">{offerList}</div>
      ) : (
        <div className="mx-auto my-auto">
          <Spinner color="warning" size="lg" />
        </div>
      )}
      {false && (
        <Pagination className="mt-auto mx-auto" total={6} color="warning" />
      )}
    </div>
  );
}
