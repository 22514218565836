import {
  Button,
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableCell,
  TableRow,
  getKeyValue,
  Tooltip,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react";
import React, { useEffect, useRef, useState } from "react";
import { EyeFill, PencilFill, Plus, TrashFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { deleteOffer, getOwnedOffers, submitForModeration } from "../services";
import { auth } from "../firebase";
import InfoChip from "./InfoChip";
import moment from "moment";

export default function OfferManager(props) {
  const isLoggedIn = auth.currentUser !== null;
  const navigate = useNavigate();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [ownedOffers, setOwnedOffers] = useState([]);
  const selectedOffer = useRef(null);
  const [renderIt, setRenderIt] = useState(0);

  useEffect(() => {
    getOffers();
  }, [isLoggedIn, renderIt]);

  const getOffers = async () => {
    const offers = await getOwnedOffers();
    if (offers) {
      setOwnedOffers(
        offers.map((offer, index) => {
          return { ...offer, key: index };
        })
      );
    }
  };

  const columns = [
    {
      key: "title",
      label: "TITLE",
    },
    {
      key: "type",
      label: "TYPE",
    },
    {
      key: "location",
      label: "LOCATION",
    },
    {
      key: "moderationStatus",
      label: "MODERATION STATUS",
    },
    {
      key: "paid",
      label: "PAYMENT STATUS",
    },
    {
      key: "added",
      label: "ADDED",
    },
    {
      key: "ends",
      label: "ENDS",
    },
    {
      key: "views",
      label: "VIEWS",
    },
    {
      key: "interested",
      label: "INTERESTED",
    },
    {
      key: "actions",
      label: "ACTIONS",
    },
  ];

  const parseKeyValue = (item, columnKey) => {
    const value = getKeyValue(item, columnKey);

    if (columnKey === "moderationStatus") {
      return (
        <InfoChip
          value={value}
          type="moderation"
          clickable={value === 0}
          onClicked={async () => {
            await submitForModeration(item.id);
            setRenderIt(renderIt + 1);
          }}
        />
      );
    }

    if (columnKey === "visible") {
      return <InfoChip value={value} type="visible" />;
    }

    if (columnKey === "paid") {
      if (item.type === "non-commercial") {
        return "Free";
      } else {
        return (
          <InfoChip value={value} type="paid" clickable={value === false} />
        );
      }
    }

    if (columnKey === "interested") {
      return value ? (
        <InfoChip value={value} type="interested" clickable />
      ) : null;
    }

    if (columnKey === "added") {
      if (value) {
        return moment(value).format("L");
      }
    }

    if (columnKey === "ends") {
      if (value) {
        return moment(value).format("L");
      }
    }

    if (columnKey === "location") {
      if (item.remote) {
        return `${value} (R)`;
      }
    }

    if (columnKey === "actions") {
      return (
        <div className="flex">
          <Tooltip content="View">
            <span
              className="mx-2 text-lg text-default-400 cursor-pointer active:opacity-50"
              onClick={() => navigate(`/offer/${item.id}`)}
            >
              <EyeFill />
            </span>
          </Tooltip>
          <Tooltip content="Edit">
            <span
              className="mx-2 text-lg text-default-400 cursor-pointer active:opacity-50"
              onClick={() => navigate(`/offers/edit/${item.id}`)}
            >
              <PencilFill />
            </span>
          </Tooltip>
          <Tooltip content="Delete">
            <span
              className="mx-2 text-lg text-default-400 cursor-pointer active:opacity-50"
              onClick={() => {
                selectedOffer.current = item.id;
                onOpen();
              }}
            >
              <TrashFill />
            </span>
          </Tooltip>
        </div>
      );
    }

    if (columnKey === "type") {
      return value === "commercial" ? "Commercial" : "Non-commercial";
    }

    return value;
  };

  return (
    <>
      <Modal
        className="dark"
        classNames={{ base: "text-slate-200" }}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        backdrop="blur"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Delete offer?
              </ModalHeader>
              <ModalBody>
                <div className="flex flex-col gap-5 w-full items-center justify-center">
                  <p className="text-xs text-slate-500 text-center">
                    Are You sure You want to remove this offer? This action is
                    irreversible and doesn't guarantee a refund.
                  </p>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  variant="light"
                  onPress={() => {
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="danger"
                  onPress={async () => {
                    await deleteOffer(selectedOffer.current);
                    setRenderIt(renderIt + 1);
                    selectedOffer.current = null;
                    onClose();
                  }}
                >
                  Delete
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <div className="flex flex-col w-full mt-24 h-full grow p-10">
        <div className="flex flex-col rounded-2xl p-10 bg-slate-900 bg-opacity-50 h-full grow">
          <div className="flex items-center gap-6 justify-between">
            <p className="text-white font-light text-xl">Manage offers</p>
            <Button
              color="primary"
              startContent={<Plus />}
              onClick={() => navigate("/offers/create")}
            >
              New offer
            </Button>
          </div>
          <div className="mt-8">
            <Table
              removeWrapper
              classNames={{
                tr: "text-white",
              }}
            >
              <TableHeader columns={columns}>
                {(column) => (
                  <TableColumn key={column.key}>{column.label}</TableColumn>
                )}
              </TableHeader>
              <TableBody
                items={ownedOffers}
                emptyContent={<p>Nothing to show</p>}
              >
                {(item) => (
                  <TableRow key={item.key}>
                    {(columnKey) => (
                      <TableCell>{parseKeyValue(item, columnKey)}</TableCell>
                    )}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
}
