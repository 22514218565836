import { Button } from "@nextui-org/react";
import { XLg } from "react-bootstrap-icons";
import React from "react";

export default function ProjectChip(props) {
  return (
    <div className="bg-slate-800 rounded-xl flex my-6">
      <img
        className="rounded-xl block"
        width={140}
        src={props.pic}
        alt="Project thumbnail"
      ></img>
      <div className="p-4">
        <p className="text-green-400 font-semibold">{props.name}</p>
        <p className="text-slate-400 text-sm">{props.role}</p>
        <p className="text-sm">{props.link}</p>
      </div>
      {props.editable && (
        <Button
          isIconOnly
          size="sm"
          variant="ghost"
          className="ml-auto my-auto relative -right-14"
          onClick={() => props.delete(props.index)}
        >
          <XLg />
        </Button>
      )}
    </div>
  );
}
