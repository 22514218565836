import React from "react";
import moment from "moment";
import { Briefcase, GeoAltFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { Chip } from "@nextui-org/react";

export default function OfferStrip(props) {
  const navigate = useNavigate();
  const { offer } = props;

  const subtypeChips = offer?.subtype?.map((subtype) => {
    return (
      <Chip size="sm" radius="sm" variant="flat">
        {subtype}
      </Chip>
    );
  });

  return (
    <div
      className="w-full bg-gray-800 p-2 mx-auto rounded-md text-white flex items-center gap-2 cursor-pointer hover:bg-gray-700"
      onClick={() => navigate(`/offer/${offer.id}`)}
    >
      <Briefcase className="text-green-500 text-2xl mx-4 hidden sm:block" />
      <div className="mr-2 grow">
        <p className="text-sm sm:text-base">{offer.title}</p>
        <p className="text-gray-400 text-xs">{`at ${offer.company}`}</p>
        <div className="mt-2 flex justify-between">
          <div className="flex items-center">
            <GeoAltFill className="text-green-500 text-xs mr-1" />
            <p className="text-gray-400 text-xs">
              {`${offer.location}${offer.remote ? " (remote)" : null}`}
            </p>
          </div>
        </div>
      </div>
      {offer.type === "commercial" ? (
        <div className="sm:mx-4 ml-auto flex flex-col items-end">
          <div className="flex gap-1">{subtypeChips}</div>
          <p className="text-sm text-green-500 font-semibold">
            {`${offer.salaryMin} - ${offer.salaryMax} ${offer.salaryCurrency}`}
          </p>
          <p className="text-xs text-gray-500 text-right">
            {moment(offer.added).fromNow()}
          </p>
        </div>
      ) : (
        <div className="sm:mx-4 ml-auto">
          <div className="flex gap-1 ml-2">{subtypeChips}</div>
          <p className="text-sm text-slate-500">Non-commercial</p>
          <p className="text-xs text-gray-500 text-right">
            {moment(offer.added).fromNow()}
          </p>
        </div>
      )}
    </div>
  );
}
