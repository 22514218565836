import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Input,
} from "@nextui-org/react";
import React, { useState } from "react";
import { Lock, At } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { tryRegister } from "../services";
import { isEmail, isLength } from "validator";

export default function Register(props) {
  const [message, setMessage] = useState(null);
  const emailRef = useRef();
  const passRef = useRef();
  const validation = useRef({
    email: true,
    pass: true,
  });

  const navigate = useNavigate();
  const handleRegister = async () => {
    setMessage(null);
    const isInputsValid = validateInputs();
    if (isInputsValid) {
      const result = await tryRegister(
        emailRef.current.value,
        passRef.current.value
      );
      if (result) {
        navigate("/");
      } else {
        setMessage("Registration error.");
      }
    } else {
      setMessage("Invalid data.");
    }
  };

  const validateInputs = () => {
    const email = isEmail(emailRef.current.value);
    const pass = isLength(passRef.current.value, { min: 6 });
    console.log(pass);
    validation.current = {
      email: email,
      pass: pass,
    };
    return email && pass;
  };

  return (
    <div className="flex justify-center mt-auto">
      <Card className="p-4">
        <CardHeader className="flex items-center justify-center mb-4">
          <p>Join the Hive</p>
        </CardHeader>
        <Divider />
        <CardBody className="flex flex-col">
          <p className="text-xs pb-1 pl-2">Email</p>
          <Input
            ref={emailRef}
            value={emailRef.current?.value}
            className="mb-4"
            startContent={<At />}
            isInvalid={!validation.current.email}
            type="email"
          />
          <p className="text-xs pb-1 pl-2">Password</p>
          <Input
            ref={passRef}
            value={passRef.current?.value}
            className="mb-2"
            startContent={<Lock />}
            isInvalid={!validation.current.pass}
            type="password"
          />
        </CardBody>
        <Divider />
        <CardFooter className="flex flex-col text-xs">
          <p className="text-red-500 pb-3">{message}</p>
          <Button color="primary" onClick={handleRegister}>
            Sign up
          </Button>
          <p className="mt-6">
            By creating an account You agree to our{" "}
            <span
              className="text-blue-400 cursor-pointer"
              onClick={() => navigate("/terms")}
            >
              Terms of service
            </span>
          </p>
          <p className="mt-2">
            Already have an account?{" "}
            <span
              className="text-blue-400 cursor-pointer"
              onClick={() => navigate("/login")}
            >
              Log in
            </span>
          </p>
        </CardFooter>
      </Card>
    </div>
  );
}
