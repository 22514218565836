import React, { useState, useEffect } from "react";
import {
  Button,
  Divider,
  ButtonGroup,
  Input,
  Textarea,
  Select,
  SelectItem,
  Switch,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Spinner,
  useDisclosure,
} from "@nextui-org/react";
import { StarFill } from "react-bootstrap-icons";
import { auth } from "../firebase";
import { getOfferById, imgServer, postOffer, uploadAvatar } from "../services";
import { useNavigate, useParams } from "react-router-dom";

export default function OfferEditor(props) {
  const navigate = useNavigate();
  const isLoggedIn = auth.currentUser !== null;
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [tempPic, setTempPic] = useState("");
  const { id } = useParams();
  const [data, setData] = useState({
    type: "commercial",
    subtype: ["full-time"],
    hero: "https://media.licdn.com/dms/image/v2/D4D16AQGFCGdpyhg4Hw/profile-displaybackgroundimage-shrink_350_1400/profile-displaybackgroundimage-shrink_350_1400/0/1710509394236?e=1735171200&v=beta&t=wb1a31ZXH5AMHPrt1Uc329uXT2P5VrwuAGuyYpM736s",
    title: "",
    company: "",
    description: "",
    location: "Poland",
    remote: true,
    salaryMin: 1000,
    salaryMax: 10000,
    salaryCurrency: "PLN",
  });
  var heroStyle = {
    backgroundImage: `url(${data?.hero ? imgServer + data.hero : null})`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: "rgba(4, 23, 13, 0.5)",
  };

  const submit = async () => {
    await postOffer(data, id ?? null);
    navigate("/offers/manage");
  };

  useEffect(() => {
    if (id) {
      getOffer();
    }
  }, [isLoggedIn]);

  const getOffer = async () => {
    const response = await getOfferById(id);
    setData(response);
  };

  const toggleSubtype = (subtype) => {
    if (!data.subtype) {
      setData({ ...data, subtype: [subtype] });
    } else {
      const index = data.subtype.indexOf(subtype);
      if (index === -1) {
        setData({ ...data, subtype: [...data.subtype, subtype] });
      } else {
        let newSubtypeArr = data.subtype;
        newSubtypeArr.splice(index, 1);
        setData({ ...data, subtype: newSubtypeArr });
      }
    }
  };

  return (
    <>
      <Modal
        className="dark"
        classNames={{ base: "text-slate-200" }}
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        backdrop="blur"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Upload hero image
              </ModalHeader>
              <ModalBody>
                <div className="flex flex-col gap-5 w-full items-center justify-center">
                  {tempPic === "WORKING" ? (
                    <Spinner />
                  ) : (
                    <div
                      className="w-full bg-slate-500 h-48 rounded-xl"
                      style={{
                        backgroundImage: `url(${imgServer + tempPic ?? null})`,
                        backgroundSize: "cover",
                      }}
                    />
                  )}
                  <p className="text-xs text-slate-500 text-center">
                    Hero image should be horizontal and not exceed 1400px in any
                    dimension.
                  </p>
                  <Input
                    type="file"
                    className="max-w-36"
                    onChange={async (e) => {
                      setTempPic("WORKING");
                      const url = await uploadAvatar(e.target.files[0]);
                      if (url) {
                        setTempPic(url);
                      } else {
                        setTempPic("");
                      }
                    }}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  variant="light"
                  onPress={() => {
                    setTempPic("");
                    onClose();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  color="default"
                  onPress={() => {
                    setData({
                      ...data,
                      hero: tempPic,
                    });
                    setTempPic("");
                    onClose();
                  }}
                >
                  Save
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
      <div className="w-full h-full grow flex text-slate-200 justify-center">
        <div className="mx-auto fixed top-20 right-16 flex gap-3">
          <Button color="default" onClick={() => navigate("/offers/manage")}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => submit()}>
            {id ? "Save" : "Create"}
          </Button>
        </div>
        <div className="w-full flex flex-col pt-48 max-w-4xl">
          <div
            className="w-full bg-slate-500 h-64 rounded-xl"
            style={heroStyle}
          ></div>
          <div className="mx-auto mt-4 flex gap-3">
            <Button variant="flat" size="sm" onClick={onOpen}>
              Change picture
            </Button>
          </div>
          <Divider className="mt-8 bg-slate-800" />
          <div className="text-sm mx-24 mt-8 text-center flex flex-col items-between gap-3">
            <div className="flex gap-5 items-center">
              <p>Offer type</p>
              <ButtonGroup className="ml-auto">
                <Button
                  size="sm"
                  color={`${
                    data.type === "commercial" ? "primary" : "default"
                  }`}
                  onClick={() => setData({ ...data, type: "commercial" })}
                >
                  Commercial
                </Button>
                <Button
                  size="sm"
                  color={`${
                    data.type === "non-commercial" ? "primary" : "default"
                  }`}
                  onClick={() => setData({ ...data, type: "non-commercial" })}
                >
                  Non-commercial
                </Button>
              </ButtonGroup>
            </div>
            <div className="flex gap-5 items-center">
              <p>Subtypes</p>
              <ButtonGroup className="ml-auto">
                <Button
                  size="sm"
                  color={
                    data.subtype?.includes("full-time") ? "warning" : "default"
                  }
                  onClick={() => toggleSubtype("full-time")}
                >
                  Full time
                </Button>
                <Button
                  size="sm"
                  color={
                    data.subtype?.includes("part-time") ? "warning" : "default"
                  }
                  onClick={() => toggleSubtype("part-time")}
                >
                  Part time
                </Button>
                <Button
                  size="sm"
                  color={
                    data.subtype?.includes("outsourcing")
                      ? "warning"
                      : "default"
                  }
                  onClick={() => toggleSubtype("outsourcing")}
                >
                  Outsourcing
                </Button>
                <Button
                  size="sm"
                  color={
                    data.subtype?.includes("freelance") ? "warning" : "default"
                  }
                  onClick={() => toggleSubtype("freelance")}
                >
                  Freelance
                </Button>
              </ButtonGroup>
            </div>
            <p className="ml-auto text-gray-400">
              Price: ${`${data.type === "non-commercial" ? "0" : "50"}`}
            </p>
            <Input
              label="Title"
              value={data.title}
              onValueChange={(e) => setData({ ...data, title: e })}
            />
            <Input
              label="Company"
              value={data.company}
              onValueChange={(e) => setData({ ...data, company: e })}
            />
            <Textarea
              label="Description"
              value={data.description}
              className="mt-6"
              minRows={10}
              maxLength={5000}
              onValueChange={(e) => setData({ ...data, description: e })}
            />
            <p className="ml-auto text-xs text-gray-400">
              {data?.description.length}/5000
            </p>
          </div>
          <div className="mx-24 mt-8 flex flex-col gap-6 justify-center text-sm">
            <Select
              isRequired
              label="Location"
              selectedKeys={[data.location]}
              onSelectionChange={(selection) => {
                setData({ ...data, location: Array.from(selection)[0] });
              }}
            >
              <SelectItem startContent={<StarFill />} key="Remote">
                Remote
              </SelectItem>
              <SelectItem key="Argentina">Argentina</SelectItem>
              <SelectItem key="Australia">Australia</SelectItem>
              <SelectItem key="Brazil">Brazil</SelectItem>
              <SelectItem key="Canada">Canada</SelectItem>
              <SelectItem key="Chile">Chile</SelectItem>
              <SelectItem key="China">China</SelectItem>
              <SelectItem key="Egypt">Egypt</SelectItem>
              <SelectItem key="Finland">Finland</SelectItem>
              <SelectItem key="France">France</SelectItem>
              <SelectItem key="Germany">Germany</SelectItem>
              <SelectItem key="India">India</SelectItem>
              <SelectItem key="Italy">Italy</SelectItem>
              <SelectItem key="Japan">Japan</SelectItem>
              <SelectItem key="Malaysia">Malaysia</SelectItem>
              <SelectItem key="Mexico">Mexico</SelectItem>
              <SelectItem key="Netherlands">Netherlands</SelectItem>
              <SelectItem key="NewZealand">New Zealand</SelectItem>
              <SelectItem key="Poland">Poland</SelectItem>
              <SelectItem key="Russia">Russia</SelectItem>
              <SelectItem key="Singapore">Singapore</SelectItem>
              <SelectItem key="SouthAfrica">South Africa</SelectItem>
              <SelectItem key="SouthKorea">South Korea</SelectItem>
              <SelectItem key="Spain">Spain</SelectItem>
              <SelectItem key="Sweden">Sweden</SelectItem>
              <SelectItem key="Ukraine">Ukraine</SelectItem>
              <SelectItem key="UnitedArabEmirates">
                United Arab Emirates
              </SelectItem>
              <SelectItem key="UnitedKingdom">United Kingdom</SelectItem>
              <SelectItem key="UnitedStates">United States</SelectItem>
              <SelectItem key="Vietnam">Vietnam</SelectItem>
            </Select>
            <div className="flex items-center justify-between">
              <p>Remote work possibility</p>
              <Switch
                isSelected={data.remote}
                onValueChange={() => setData({ ...data, remote: !data.remote })}
              />
            </div>
            <div className="flex items-center justify-between">
              <p>Salary range</p>
              <div className="flex gap-3">
                <Input
                  isRequired
                  label="Salary Min"
                  type="number"
                  value={data.salaryMin}
                  onValueChange={(e) => setData({ ...data, salaryMin: e })}
                />
                <Input
                  isRequired
                  label="Salary Max"
                  type="number"
                  value={data.salaryMax}
                  onValueChange={(e) => setData({ ...data, salaryMax: e })}
                />
                <Select
                  isRequired
                  label="Currency"
                  selectedKeys={[data.salaryCurrency]}
                  onSelectionChange={(selection) => {
                    setData({
                      ...data,
                      salaryCurrency: Array.from(selection)[0],
                    });
                  }}
                >
                  <SelectItem key="PLN">PLN</SelectItem>
                  <SelectItem key="USD">USD</SelectItem>
                  <SelectItem key="EUR">EUR</SelectItem>
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
