import React from "react";
import { InfoCircle } from "react-bootstrap-icons";

export default function News(props) {
  return (
    <div className="absolute top-20 inset-x-0 flex justify-center max-w-72 sm:max-w-max mx-auto">
      <div className="text-white text-sm mx-auto flex gap-2 p-4 rounded-xl py-2 bg-slate-800 items-center">
        <InfoCircle />
        <h1 className="text-xs sm:text-sm md:text-base text-yellow-500 font-semibold">
          Welcome to HackerHive!
        </h1>
        <p className="text-xs sm:text-sm md:text-base text-slate-300">
          Commercial job offers are FREE until the end of 2024.
        </p>
      </div>
    </div>
  );
}
