import React, { useEffect, useState } from "react";
import { Button } from "@nextui-org/react";
import News from "./News";
import {
  Binoculars,
  Bullseye,
  Chat,
  Controller,
  PersonPlusFill,
  PersonRaisedHand,
  Search,
} from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import OfferStrip from "./OfferStrip";
import Herobg from "../img/herobg.png";
import { getOffers } from "../services";

export default function Home(props) {
  const navigate = useNavigate();
  const [offers, setOffers] = useState(null);

  useEffect(() => {
    getLatestOffers();
  }, []);

  const getLatestOffers = async () => {
    const results = await getOffers("all", "all", false, false, 4, true);
    if (results) {
      setOffers(results);
    }
  };

  const homeOffers = offers?.map((offer) => {
    return <OfferStrip offer={offer} />;
  });

  return (
    <div className="pt-20">
      <News />
      <div
        className="py-24 flex flex-col justify-center"
        style={{
          backgroundImage: `url(${Herobg})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          height: "35vw",
        }}
      >
        <div className="bg-black p-3 mx-auto">
          <h1 className="text-2xl sm:text-4xl md:text-6xl text-green-400 text-center">
            Gamedev connected.
          </h1>
          <div className="absolute inset-x-0 flex flex-col justify-center pt-2">
            <p className="text-sm sm:text-base text-white mx-auto text-center pt-4 max-w-3xl">
              Showcase your talent, and connect with the game industry.
            </p>
            <Button
              className="mx-auto my-6 sm:my-12 font-semibold"
              color="warning"
              startContent={<PersonPlusFill />}
              onClick={() => navigate("register")}
            >
              Join the Hive
            </Button>
          </div>
        </div>
      </div>
      <div className="py-8 mt-16 sm:mt-0 flex flex-col justify-center">
        <div className="flex flex-col sm:flex-row items-center mx-auto">
          <div className="text-green-400 text-7xl md:text-9xl h-fit">
            <Controller />
          </div>
          <div className="text-black p-8 pt-2 sm:p-8 text-center sm:text-left">
            <h3 className="text-xl sm:text-2xl font-semibold text-green-400 text-center sm:text-left">
              For Game Developers & Creatives
            </h3>
            <h5 className="italic mb-4 text-slate-400">
              Showcase Your Skills, Get Discovered
            </h5>
            <p className="text-xs sm:text-sm font-semibold text-slate-300">
              <span>&#8226;</span> Create a profile that highlights your
              projects, skills, and experience.
            </p>
            <p className="text-xs sm:text-sm font-semibold text-slate-300">
              <span>&#8226;</span> Show off your portfolio and connect directly
              with recruiters looking for talent.
            </p>
            <p className="text-xs sm:text-sm font-semibold text-slate-300">
              <span>&#8226;</span> Find like-minded collaborators for your next
              big project.
            </p>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row items-center mx-auto mt-8 sm:mt-24 text-center sm:text-left">
          <div className="sm:hidden text-green-400 text-7xl h-fit">
            <Binoculars />
          </div>
          <div className="text-black p-8">
            <h3 className="text-2xl font-semibold text-green-400">
              For Recruiters
            </h3>
            <h5 className="italic mb-4 text-slate-400">
              Find Top Talent in Game Development
            </h5>
            <p className="text-xs sm:text-sm font-semibold text-slate-300">
              <span>&#8226;</span> Search and filter through skilled developers
              and creatives ready to join your team.
            </p>
            <p className="text-xs sm:text-sm font-semibold text-slate-300">
              <span>&#8226;</span> Discover portfolios that showcase real
              projects and proven skills.
            </p>
            <p className="text-xs sm:text-sm font-semibold text-slate-300">
              <span>&#8226;</span> Streamline your recruitment process with
              focused talent search and messaging tools.
            </p>
          </div>
          <div className="hidden sm:block text-green-400 text-9xl h-fit">
            <Binoculars />
          </div>
        </div>
        <div className="mt-24">
          <h3 className="text-white text-xl font-semibold text-center">
            Latest offers
          </h3>
          <div className="w-4/5 xl:w-2/5 mx-auto flex flex-col gap-2 mt-8">
            {offers ? (
              homeOffers
            ) : (
              <p className="mx-auto my-6 text-slate-600">Nothing to show</p>
            )}
          </div>
        </div>
        <Button
          className="mx-auto mt-8"
          color="warning"
          variant="bordered"
          onClick={() => navigate("/offers")}
        >
          Browse Jobs
        </Button>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-5 mt-16 sm:mt-24">
          <div className="flex flex-col items-center">
            <div className="text-green-400 text-2xl sm:text-5xl h-fit">
              <Bullseye />
            </div>
            <h5 className="text-slate-200 text-center mt-4 text-sm sm:text-base">
              Efficient & Focused
            </h5>
            <p className="text-slate-500 text-xs sm:text-sm max-w-64 text-center">
              No bullshit - just your portfolio and your talent.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-green-400 text-2xl sm:text-5xl h-fit">
              <Search />
            </div>
            <h5 className="text-slate-200 text-center mt-4 text-sm sm:text-base">
              Tailored Search
            </h5>
            <p className="text-slate-500 text-xs sm:text-sm max-w-64 text-center">
              Advanced filters to help recruiters find the right skills.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-green-400 text-2xl sm:text-5xl h-fit">
              <Chat />
            </div>
            <h5 className="text-slate-200 text-center mt-4 text-sm sm:text-base">
              Direct Messaging
            </h5>
            <p className="text-slate-500 text-xs sm:text-sm max-w-64 text-center">
              Connect instantly with potential collaborators or employers.
            </p>
          </div>
          <div className="flex flex-col items-center">
            <div className="text-green-400 text-2xl sm:text-5xl h-fit">
              <PersonRaisedHand />
            </div>
            <h5 className="text-slate-200 text-center mt-4 text-sm sm:text-base">
              Collaborative Opportunities
            </h5>
            <p className="text-slate-500 text-xs sm:text-sm max-w-64 text-center">
              Join projects and expand your network in the game development
              space.
            </p>
          </div>
        </div>
        <p className="text-3xl text-slate-200 text-center mt-24 mb-12">
          Ready to Level Up?
        </p>
        <Button
          className="mx-auto"
          color="warning"
          variant="bordered"
          onClick={() => navigate("register")}
        >
          Start Connecting Now
        </Button>
      </div>
    </div>
  );
}
