import React, { useEffect, useState } from "react";
import { Select, SelectItem, Spinner, Slider, Input } from "@nextui-org/react";
import { getUsers } from "../services";
import UserStrip from "./UserStrip";
import { Search } from "react-bootstrap-icons";

export default function Radar(props) {
  const [filters, setFilters] = useState({
    location: "all",
  });

  const [results, setResults] = useState(null);

  useEffect(() => {
    fetchUsers();
  }, [filters]);

  const fetchUsers = async () => {
    setResults(null);
    const result = await getUsers(filters.location, false, 25);
    if (result) {
      setResults(result);
    } else {
      setResults([]);
    }
  };

  const userStrips = results?.map((user) => {
    return <UserStrip user={user} />;
  });

  const resultsView = () => {
    if (results === null) return <Spinner size="lg" color="warning" />;
    if (results.length === 0) return <p>No results</p>;
    if (results) return userStrips;
  };

  return (
    <div className="flex flex-col w-3/5 mt-24 h-full grow p-10 mx-auto">
      <Input
        type="text"
        size="lg"
        startContent={<Search className="text-white" />}
      />
      <div className="flex gap-3 mt-3">
        <Select label="Category" size="sm">
          <SelectItem key="All">All</SelectItem>
          <SelectItem key="Programmer">Programmer</SelectItem>
          <SelectItem key="3D Artist">3D Artist</SelectItem>
          <SelectItem key="2D Artist">2D Artist</SelectItem>
          <SelectItem key="Writer">Writer</SelectItem>
          <SelectItem key="Sound Designer">Sound Designer</SelectItem>
          <SelectItem key="Manager">Manager</SelectItem>
          <SelectItem key="Tester">Tester</SelectItem>
        </Select>
        <Select
          label="Location"
          size="sm"
          selectedKeys={[filters.location]}
          onSelectionChange={(selection) => {
            setFilters({ ...filters, location: Array.from(selection)[0] });
          }}
        >
          <SelectItem key="all">Any</SelectItem>
          <SelectItem key="Argentina">Argentina</SelectItem>
          <SelectItem key="Australia">Australia</SelectItem>
          <SelectItem key="Brazil">Brazil</SelectItem>
          <SelectItem key="Canada">Canada</SelectItem>
          <SelectItem key="Chile">Chile</SelectItem>
          <SelectItem key="China">China</SelectItem>
          <SelectItem key="Egypt">Egypt</SelectItem>
          <SelectItem key="Finland">Finland</SelectItem>
          <SelectItem key="France">France</SelectItem>
          <SelectItem key="Germany">Germany</SelectItem>
          <SelectItem key="India">India</SelectItem>
          <SelectItem key="Italy">Italy</SelectItem>
          <SelectItem key="Japan">Japan</SelectItem>
          <SelectItem key="Malaysia">Malaysia</SelectItem>
          <SelectItem key="Mexico">Mexico</SelectItem>
          <SelectItem key="Netherlands">Netherlands</SelectItem>
          <SelectItem key="NewZealand">New Zealand</SelectItem>
          <SelectItem key="Poland">Poland</SelectItem>
          <SelectItem key="Russia">Russia</SelectItem>
          <SelectItem key="Singapore">Singapore</SelectItem>
          <SelectItem key="SouthAfrica">South Africa</SelectItem>
          <SelectItem key="SouthKorea">South Korea</SelectItem>
          <SelectItem key="Spain">Spain</SelectItem>
          <SelectItem key="Sweden">Sweden</SelectItem>
          <SelectItem key="Ukraine">Ukraine</SelectItem>
          <SelectItem key="UnitedArabEmirates">United Arab Emirates</SelectItem>
          <SelectItem key="UnitedKingdom">United Kingdom</SelectItem>
          <SelectItem key="UnitedStates">United States</SelectItem>
          <SelectItem key="Vietnam">Vietnam</SelectItem>
        </Select>

        <Slider
          label="Experience (yrs)"
          step={1}
          maxValue={10}
          minValue={0}
          defaultValue={1}
          size="sm"
          color="warning"
          className="max-w-md text-white"
        />
      </div>
      <div className="mt-6">{resultsView()}</div>
    </div>
  );
}
