import React, { useEffect, useState } from "react";
import { auth } from "../firebase";
import { useNavigate, useParams } from "react-router-dom";
import { getOfferById, imgServer, imInterested } from "../services";
import { Button, Divider, Spinner } from "@nextui-org/react";
import { HandThumbsUpFill } from "react-bootstrap-icons";

export default function OfferView(props) {
  const isLoggedIn = auth.currentUser !== null;
  const navigate = useNavigate();
  const [offer, setOffer] = useState(null);
  const [render, setRender] = useState(0);
  const { id } = useParams();
  const notOwner = !auth.currentUser || offer?.owner !== auth.currentUser.uid;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getOffer();
  }, [isLoggedIn, render]);

  const getOffer = async () => {
    const response = await getOfferById(id);
    setOffer(response);
  };

  const onInterested = async () => {
    setLoading(true);
    if (!auth.currentUser) {
      navigate("/");
    } else {
      await imInterested(id);
      await props.refresh();
      setRender(1);
      setLoading(false);
    }
  };

  return (
    <div className="flex flex-col w-full xl:w-3/5 mt-16 sm:mt-24 h-full grow md:p-10 mx-auto">
      <div className="flex flex-col rounded-2xl p-2 bg-black bg-opacity-20 h-full grow">
        {!offer && (
          <div className="text-gray-400 mx-auto my-auto">
            <p>Offer not found.</p>
          </div>
        )}
        {offer && (
          <div>
            <div
              className="rounded-xl w-full h-28 sm:h-36 md:h-48 xl:h-56"
              style={{
                backgroundImage: `url(${imgServer + offer.hero})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
              }}
            />
            <div className="mt-6 mx-6 flex items-center justify-between">
              <div>
                <div className="flex gap-3 items-center">
                  <p className="font-semibold text-xl sm:text-2xl text-green-400">
                    {offer.title}
                  </p>
                  <div
                    className={`p-1 rounded text-xs font-semibold ${
                      offer.type === "commercial"
                        ? "bg-amber-500"
                        : "bg-indigo-700"
                    }`}
                  >
                    <p>{offer.type}</p>
                  </div>
                </div>
                <p className="text-slate-400 text-xs">{`for: ${offer.company}`}</p>
                <p className="text-slate-400 text-xs">{`at: ${offer.location} ${
                  offer.location !== "Remote" && offer.remote ? "(remote)" : ""
                }`}</p>
                <p className="text-slate-400 text-xs">{`${offer.salaryMin} - ${offer.salaryMax} ${offer.salaryCurrency}`}</p>
              </div>
              <div className="flex gap-2">
                {notOwner && (
                  <div>
                    {loading ? (
                      <Spinner />
                    ) : (
                      <div>
                        {!props.appliedOffers ||
                        !props.appliedOffers.includes(id) ? (
                          <div>
                            <Button
                              variant="flat"
                              color="warning"
                              className="hidden sm:flex"
                              startContent={<HandThumbsUpFill />}
                              onClick={onInterested}
                            >
                              I'm interested
                            </Button>
                            <Button
                              variant="flat"
                              color="warning"
                              className="sm:hidden"
                              isIconOnly
                              startContent={<HandThumbsUpFill />}
                              onClick={onInterested}
                            />
                          </div>
                        ) : (
                          <p className="text-green-400">Interested</p>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <Divider className="mt-6" />
            <div className="flex justify-end gap-3 mx-6">
              <pre>
                <p className="text-slate-300 my-6 text-sm text-balance">
                  {offer.description}
                </p>
              </pre>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
