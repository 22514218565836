import React from "react";
import { Divider } from "@nextui-org/react";

export default function Privacy(props) {
  return (
    <div className="flex flex-col w-3/5 mt-24 h-full grow p-10 mx-auto">
      <div className="flex flex-col rounded-2xl p-2 bg-black bg-opacity-20 h-full grow">
        <div>
          <div className="mt-6 mx-6 flex justify-center">
            <p className="font-rgular text-2xl text-green-400">
              Privacy policy
            </p>
          </div>
          <Divider className="mt-6" />
          <div className="flex justify-end gap-3 mx-6">
            <p className="text-slate-300 mt-6 text-sm">
              1.{" "}
              <span className="font-semibold text-green-500">
                Information We Collect
              </span>{" "}
              <br />
              Account Information: When users register, we collect basic
              information, such as email address, and password. <br />
              Profile Information: Users may voluntarily provide details such as
              skills, portfolio links, and job preferences. <br />
              Messaging Data: Messages sent through our platform may be stored
              for moderation and user security. <br />
              Cookies and Tracking: We may use cookies and similar technologies
              to collect information about platform usage. <br />
              <br />
              2.{" "}
              <span className="font-semibold text-green-500">
                How We Use Information
              </span>
              <br /> Provide Services: We use your information to enable
              platform features like profiles, messaging, and job postings.{" "}
              <br />
              Improve Platform: Data helps us to analyze and improve user
              experience and functionality. <br />
              Security: Information may be used to detect and prevent fraud,
              abuse, or unauthorized access. <br />
              <br />
              3.{" "}
              <span className="font-semibold text-green-500">
                Sharing Information With Other
              </span>
              <br /> Users: Profile information, skills, and portfolio links are
              shared with other users to facilitate networking and job
              opportunities. <br />
              Service Providers: We may share information with third-party
              providers who assist in operating the platform, subject to
              confidentiality agreements. <br />
              Legal Obligations: Information may be disclosed to comply with
              legal requirements, such as court orders, or to protect the rights
              and safety of users and the platform. <br />
              <br />
              4.{" "}
              <span className="font-semibold text-green-500">
                Data Retention
              </span>
              <br /> We retain personal information for as long as is necessary
              to provide services, fulfill legal obligations, and resolve
              disputes. <br />
              <br />
              5.{" "}
              <span className="font-semibold text-green-500">User Rights</span>
              <br /> Access and Correction: Users can access and update their
              information through their profile settings. <br />
              Deletion: Users may request deletion of their account and
              associated data by contacting support. <br /> <br />
              <br />
              6. <span className="font-semibold text-green-500">Security</span>
              <br /> We implement industry-standard security measures to protect
              user data. However, no method of transmission or storage is
              completely secure, and we cannot guarantee absolute security.{" "}
              <br />
              <br />
              7.{" "}
              <span className="font-semibold text-green-500">
                Changes to the Privacy Policy
              </span>
              <br /> We may update this Privacy Policy as necessary. Users will
              be notified of any significant changes. <br />
              <br />
              8.{" "}
              <span className="font-semibold text-green-500">Contact Us</span>
              <br /> For questions or concerns about these policies, please
              contact us at bankai@bankai.pl.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
