import { Button } from "@nextui-org/react";
import React from "react";
import Cookies from "js-cookie";
import { setConsent } from "firebase/analytics";
import { useNavigate } from "react-router-dom";

export default function Footer(props) {
  const navigate = useNavigate();
  const setCookies = (value) => {
    if (!value) {
      setConsent({
        ad_personalization: "denied",
        ad_storage: "denied",
        ad_user_data: "denied",
        analytics_storage: "denied",
        functionality_storage: "granted",
        personalization_storage: "granted",
        security_storage: "granted",
      });
    } else {
      setConsent({
        ad_personalization: "denied",
        ad_storage: "denied",
        ad_user_data: "denied",
        analytics_storage: "granted",
        functionality_storage: "granted",
        personalization_storage: "granted",
        security_storage: "granted",
      });
    }
    Cookies.set("cookiesSetup", "true");
    window.location.reload();
  };

  return (
    <div className="mt-auto pt-8 text-xs text-slate-500 text-center">
      <p>HackerHive @2024</p>
      <div className="py-2 flex gap-4 mt-2 justify-center bg-slate-900 bg-opacity-50">
        <p
          className="cursor-pointer hover:text-white"
          onClick={() => navigate("terms")}
        >
          Terms and conditions
        </p>
        <p
          className="cursor-pointer hover:text-white"
          onClick={() => navigate("privacy")}
        >
          Privacy policy
        </p>
        <p
          className="cursor-pointer hover:text-white"
          onClick={() => window.open("https://discord.gg/fnq5TprnGN", "_blank")}
        >
          Join our Discord
        </p>
      </div>
      {props.showCookies && (
        <div className="fixed bottom-8 w-full flex justify-center">
          <div className="bg-slate-800 text-slate-300 p-4 rounded-xl flex items-center gap-3 text-left">
            <p>
              HackerHive uses some basic cookies to function properly. <br />
              We would also like to use some additional cookies for analytics
              purposes.
            </p>
            <Button onClick={() => setCookies(false)}>Necessary only</Button>
            <Button onClick={() => setCookies(true)} color="primary">
              Accept all
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}
