import "./App.css";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Login from "./components/Login";
import Navigation from "./components/Navigation";
import Profile from "./components/Profile";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "./components/Register";
import { useEffect, useRef, useState } from "react";
import { fetchMyProfile } from "./services";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";
import ProfileEdit from "./components/ProfileEdit";
import Messages from "./components/Messages";
import OfferManager from "./components/OfferManager";
import OfferEditor from "./components/OfferEditor";
import OfferView from "./components/OfferView";
import Cookies from "js-cookie";
import TermsAndConditions from "./components/TermsAndConditions";
import Privacy from "./components/Privacy";
import OfferExplorer from "./components/OfferExplorer";
import Radar from "./components/Radar";

function App() {
  const [state, setState] = useState({
    profile: undefined,
    rerender: true,
  });
  const cookiesSetup = useRef(1);

  onAuthStateChanged(auth, (user) => {
    if (user && state.rerender) {
      updateProfile();
    }
  });

  async function updateProfile() {
    const result = await fetchMyProfile();
    if (result) {
      setState({ ...state, profile: result, rerender: false });
    }
  }

  useEffect(() => {
    if (cookiesSetup.current === 1) {
      const cookieValue = Cookies.get("cookiesSetup");
      if (cookieValue) {
        cookiesSetup.current = cookieValue;
      } else {
        cookiesSetup.current = false;
      }
    }
  }, [cookiesSetup]);

  return (
    <div className="App min-h-screen flex flex-col">
      <BrowserRouter>
        <Navigation profile={state.profile} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/:id" element={<Profile />} />
          <Route path="messages/:id" element={<Messages />} />
          <Route path="messages" element={<Messages />} />
          <Route path="offers/manage" element={<OfferManager />} />
          <Route path="offers" element={<OfferExplorer />} />
          <Route
            path="offer/:id"
            element={
              <OfferView
                appliedOffers={state.profile?.appliedOffers}
                refresh={updateProfile}
              />
            }
          />
          <Route path="offers/create" element={<OfferEditor />} />
          <Route path="offers/edit/:id" element={<OfferEditor />} />
          <Route
            path="profile/edit"
            element={<ProfileEdit profile={state.profile} />}
          />
          <Route path="radar" element={<Radar />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="terms" element={<TermsAndConditions />} />
          <Route path="privacy" element={<Privacy />} />
        </Routes>
        <Footer showCookies={!cookiesSetup.current} />
      </BrowserRouter>
    </div>
  );
}

export default App;
