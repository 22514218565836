import React from "react";
import { Divider } from "@nextui-org/react";

export default function TermsAndConditions(props) {
  return (
    <div className="flex flex-col w-3/5 mt-24 h-full grow p-10 mx-auto">
      <div className="flex flex-col rounded-2xl p-2 bg-black bg-opacity-20 h-full grow">
        <div>
          <div className="mt-6 mx-6 flex justify-center">
            <p className="font-rgular text-2xl text-green-400">
              Terms and conditions
            </p>
          </div>
          <Divider className="mt-6" />
          <div className="flex justify-end gap-3 mx-6">
            <p className="text-slate-300 mt-6 text-sm">
              Owner of HackerHive is Bankai Software Jonasz Bigda based in ul.
              Emilii Plater 17, 06-400 Ciechanów, NIP: 7422235479.
              <br /> <br />
              1. Welcome to HackerHive. By accessing or using our platform, you
              agree to comply with and be bound by the following terms and
              conditions "Terms". Please read them carefully before using our
              services. <br />
              <br />
              2. Users may create an account by providing accurate and complete
              information. Users are responsible for safeguarding their account
              details, including their password, and agree not to disclose these
              details to third parties. <br />
              <br />
              3. HackerHive complies with the European Union General Data
              Protection Regulation (GDPR) for users within the European
              Economic Area (EEA). This includes respecting user rights over
              their personal data, such as the right to access, correct, delete,
              and restrict data processing. Users in the EEA may exercise their
              rights by contacting HackerHive’s Data Protection Officer at
              bankai@bankai.pl. In the event of a data breach, HackerHive will
              promptly notify affected users in accordance with GDPR
              requirements and cooperate with regulatory authorities as
              necessary. Users have the right to withdraw consent for data
              processing where consent is the legal basis, and this may be done
              by managing account settings or contacting support.
              <br />
              <br />
              4. Users are expected to use the platform responsibly, respecting
              others' rights and refraining from any illegal, abusive, or
              inappropriate activity, including harassment or posting offensive
              content. Users agree not to engage in any spamming, hacking, or
              unauthorized data scraping of the platform. Job postings must
              adhere to all employment and anti-discrimination laws relevant to
              their jurisdiction. <br />
              <br />
              5. Users can share portfolio links, game references and job
              offers. They retain ownership of the content they post but grant
              HackerHive a license to display, and promote such content on the
              platform. Users agree not to post content that is false,
              misleading, or infringes on any intellectual property rights.{" "}
              <br />
              <br />
              6. HackerHive provides a messaging system for users to communicate
              about job opportunities or collaborations. However, users are
              prohibited from spamming or using the messaging system to harass
              others. HackerHive reserves the right to review, moderate, and, if
              necessary, delete messages that violate these Terms.
              <br />
              <br />
              7. HackerHive is not responsible for any job offers, employment
              agreements, or transactions between users. All agreements are made
              at the users' discretion and responsibility. HackerHive does not
              guarantee the legitimacy, quality, or suitability of any job
              offers listed on the platform. <br />
              <br />
              8. HackerHive and its content, logos, and design are protected by
              intellectual property laws. Users may not copy, distribute, or
              modify any platform materials without express permission. <br />
              <br />
              9. HackerHive is not liable for any damages or losses resulting
              from the use of the platform, including but not limited to data
              loss, lost profits, or damages arising from third-party actions.
              <br />
              <br />
              10. Termination of Account HackerHive reserves the right to
              suspend or terminate any account that violates these Terms or
              engages in harmful or illegal activities. <br />
              <br />
              11. Changes to the Terms HackerHive may update these Terms
              occasionally. Users will be notified of significant changes, and
              continued use of the platform constitutes acceptance of the
              updated Terms. <br />
              <br />
              12. These Terms are governed by Polish law. Any disputes will be
              resolved in the courts of Poland.
            </p>
          </div>
          <p className="my-6 text-center text-slate-400">
            If You have any questions or concerns, please contact us at
            bankai@bankai.pl
          </p>
        </div>
      </div>
    </div>
  );
}
