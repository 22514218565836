import React, { useEffect, useState } from "react";
import { Avatar, Button, Divider, Spinner } from "@nextui-org/react";
import { EnvelopeFill, Link, StarFill } from "react-bootstrap-icons";
import InfoChipProfile from "./InfoChipProfile";
import SkillChip from "./SkillChip";
import ProjectChip from "./ProjectChip";
import { useNavigate, useParams } from "react-router-dom";
import { fetchProfile, imgServer } from "../services";
import { auth } from "./../firebase";

export default function Profile(props) {
  const isLoggedIn = auth.currentUser !== null;
  const navigate = useNavigate();
  let { id } = useParams();
  const isShortcut = id.length <= 20;
  const [profile, setProfile] = useState(false);

  useEffect(() => {
    async function getData() {
      const data = await fetchProfile(id, isShortcut);
      if (data) {
        setProfile(data);
      }
    }
    getData();
  }, []);

  const skillMap = profile.skills?.map((skill, index) => {
    return (
      <SkillChip
        title={skill.name}
        seniority={skill.seniority}
        icon={skill.icon}
        index={index}
        key={index}
      />
    );
  });

  const projectMap = profile.projects?.map((project, index) => {
    return (
      <ProjectChip
        name={project.name}
        pic={project.pic}
        role={project.role}
        link={project.link}
        index={index}
        key={index}
      />
    );
  });

  const linkMap = profile.links?.map((link, index) => {
    return (
      <Button key={index} color="success" size="sm" startContent={<Link />}>
        {link.label}
      </Button>
    );
  });

  return (
    <div className="w-full h-full flex text-slate-200 justify-center">
      {!profile && <Spinner size="lg" />}
      {profile && (
        <div className="flex flex-col pt-48 max-w-2xl">
          <div className="mx-auto">
            <Avatar
              showFallback
              isBordered
              src={`${imgServer}${profile.pic}`}
              className="w-40 h-40"
            />
          </div>
          <div className="mx-auto text-center flex flex-col items-center">
            <h1 className="text-xl mt-4">{profile.name}</h1>
            <p className="text-slate-500">{profile.position}</p>
            <div
              className={`p-1 px-2 mt-2 rounded-lg mx-auto ${
                profile.type === "HACKER" ? "bg-sky-400" : "bg-teal-500"
              }`}
            >
              <p className="text-black font-semibold text-xs">{profile.type}</p>
            </div>
            {isLoggedIn && auth.currentUser?.uid !== profile.id ? (
              <div className="mt-4 flex gap-3">
                <Button
                  variant="flat"
                  size="sm"
                  startContent={<EnvelopeFill />}
                  color="success"
                  onClick={() => navigate(`/messages/${profile.id}`)}
                >
                  Message
                </Button>
                <Button isIconOnly size="sm" variant="flat" color="success">
                  <StarFill />
                </Button>
              </div>
            ) : (
              <div className="mt-4 text-gray-400 text-xs">
                <p>
                  {auth.currentUser?.uid === profile.id
                    ? ""
                    : "Log in to contact this user"}
                </p>
              </div>
            )}
          </div>
          <Divider className="mt-8 bg-slate-800" />
          <h5 className="text-xl mt-8 text-center text-slate-400">Overview</h5>
          <p className="text-slate-400 mt-8 text-pretty">
            {profile.description}
          </p>
          <div className="mt-8 flex gap-4 justify-center">
            {profile.type === "HACKER" && (
              <InfoChipProfile
                title="Exp"
                value={`${profile.experience} yrs`}
              />
            )}
            {profile.finExpectationsCurrency !== 0 &&
              profile.type === "HACKER" && (
                <InfoChipProfile
                  title="Fin. expectations"
                  value={`${profile.finExpectations} ${profile.finExpectationsCurrency}`}
                />
              )}
            {profile.location && (
              <InfoChipProfile
                title="Location"
                value={`${profile.location} | ${profile.country}`}
              />
            )}
          </div>
          {profile.type === "HACKER" && (
            <div>
              <Divider className="mt-8 bg-slate-800" />
              <h5 className="text-xl mt-8 text-center text-slate-400">
                Skills
              </h5>
              <div className="grid grid-cols-2 gap-4 mt-8">{skillMap}</div>
            </div>
          )}
          <Divider className="mt-8 bg-slate-800" />
          <h5 className="text-xl mt-8 text-center text-slate-400">Projects</h5>
          <p className="mx-auto text-sm text-slate-600 text-center italic">
            {profile.type === "HACKER"
              ? "I was/am a part of the following projects"
              : "We are responsible for the following projects"}
          </p>
          <div className="mt-6">{projectMap}</div>
          <Divider className="mt-8 bg-slate-800" />
          <h5 className="text-xl mt-8 text-center text-slate-400">Offers</h5>
          <p className="text-slate-600 text-center my-6">No active offers.</p>
          <Divider className="mt-8 bg-slate-800" />
          <h5 className="text-xl mt-8 text-center text-slate-400">Connect</h5>
          <div className="flex gap-3 justify-center pt-4">{linkMap}</div>
        </div>
      )}
    </div>
  );
}
