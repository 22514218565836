import React from "react";

export default function InfoChipProfile(props) {
  return (
    <div className="bg-gray-800 text-xs text-white rounded-lg flex gap-2 items-center">
      <p className="ml-2">{props.title}</p>
      <div className="bg-green-400 text-black p-1 rounded-r-lg">
        <p>{props.value}</p>
      </div>
    </div>
  );
}
